import axios from "axios";
import APIConfig from '../.././/src/APIConfig';
const APIURL ="https://ecommerceapi.cloudpub.in";//  "http://localhost:53042/" //"http://localhost:53042/";//= 
//
const accessCode ="lknoXi8oXYZp9U3Xd/6vngMBRKImyBVgs67AKIH+GnY=";//"QUnfATBLxQWfzwxLe9+itWaMqWx8BpUFZ6ZwsqiwkP0=";// 
//const accessCode2= "QUnfATBLxQWfzwxLe9+itWaMqWx8BpUFZ6ZwsqiwkP0=";
//const CompanyComponent = "https://ecommerceapi.cloudpub.in";
// const iCompanyID = sessionStorage.getItem("iCompanyID");`
// const iBranchID = sessionStorage.getItem("iBranchID");
// const FinancialPeriod = sessionStorage.getItem("FinancialPeriod");
var CompID = sessionStorage.getItem("iCompanyID");
const iCompanyID = ((CompID === null || CompID ==="" || CompID === undefined)?1:CompID);
var BranchID = sessionStorage.getItem("iBranchID");
const iBranchID = ((BranchID === null || BranchID === "" || BranchID === undefined)?1:BranchID); 
const FinancialPeriod = sessionStorage.getItem("FinancialPeriod");
// const strUserID  = localStorage.getItem("EmailID");
// const  SessionID  =localStorage.getItem('SessionID');
// const CustID= localStorage.getItem("CustID");

//  const CompanyName = sessionStorage.getItem("CompanyName");


 function WebEncryptData(TextValue, ActionType){
  var config = {
      method: 'post',
      url: APIURL + '/api/Web_Text_EncodeDecode?textForEncodeDecode='+ TextValue +'&ActionType='+ActionType,
      headers: {
        'Authorization': 'Basic ' + APIConfig
      }
    };
   return  axios(config)
}

async function WebGetSEO(Content){
  var config = {
      method: 'post',
      
      url:'https://ecommerceapi.cloudpub.in/api/ECoMsitemapDynamic?content='+Content+'&iCompanyID='+iCompanyID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebGetCompanyDetail(){
  var config = {
      method: 'post',
      async: false,  
      url: APIURL+'/api/WebCompanyInfo?accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}

async function WebgetSiteMap(){
  var config = {
      method: 'GET',
      url: APIURL+'/api/ECoMsitemap?Content=WRITEXMLFOREDUCATION&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
       
      }
    };
   return await axios(config)
}
async function WebGetSliderHomePage(SliderSeq){
  var config = {
      method: 'post',
      url: APIURL + '/api/WebGetSliderHomePage?iCompanyID='+ iCompanyID +'&iBranchID='+ iBranchID +'&accessCode='+ accessCode +'&SLiderSeq='+SliderSeq,
      headers: {
        'Authorization': 'Basic ' + APIConfig
      }
    };
   return await axios(config)
}


async function BookSaleSliderAPI(){
    var config = {
        method: 'post',
        url: APIURL + '/api/WebGetSliderHomePage?iCompanyID='+ iCompanyID +'&iBranchID='+ iBranchID +'&accessCode='+ accessCode +'&SLiderSeq=4',
        headers: {
          'Authorization': 'Basic '+APIConfig
        }
      };
     return await axios(config)
}

async function WebGetSliderHomePage1(){

  var config = {
      method: 'post',
      url: APIURL + '/api/WebGetSliderHomePage?iCompanyID='+ iCompanyID +'&iBranchID='+ iBranchID +'&accessCode='+ accessCode +'&SLiderSeq=1',
      headers: {
        'Authorization': 'Basic ' + APIConfig
      }
    };
   return await axios(config)
}

async function GetWebGetSlideBanner1(){
    var config = {
        method: 'post',
        url: APIURL+'/api/WebGetSlideBanner1?iCompanyID='+ iCompanyID+'&accessCode='+ accessCode,
        headers: {
          'Authorization': 'Basic '+APIConfig
        }
      };
     return await axios(config)
}
async function GetWebGetSlideBanner2(){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetSlideBanner2?iCompanyID='+ iCompanyID+'&accessCode='+ accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}

async function GetWebGetBookDetail(strBookCode){
  var strOtherCountry = "INDIA";
    var config = {
        method: 'post',
        url: APIURL + '/api/WebGetBookDetail?iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&strOtherCountry='+strOtherCountry+'&strBookCode='+strBookCode+'&accessCode='+accessCode,
        headers: {
          'Authorization': 'Basic '+APIConfig
        }
      };
     return await axios(config)
}
async function GetWebGetBookReview(strOtherCountry, strBookCode){
  strOtherCountry = "INDIA";
    var config = {
        method: 'post',
        url: APIURL+'/api/WebGetBookReview?iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&strOtherCountry='+strOtherCountry+'&strBookCode='+strBookCode+'&accessCode='+accessCode,
        headers: {
          'Authorization': 'Basic '+APIConfig
        }
      };
     return await axios(config)
}
async function GetWebGetSimilarBooks(strBookCode){
  var strOtherCountry = "INDIA";
  var config = {
        method: 'post',
        url: APIURL + '/api/WebGetSimilarBooks?iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&strOtherCountry='+strOtherCountry+'&strBookCode='+strBookCode+'&accessCode='+accessCode,
        headers: {
          'Authorization': 'Basic '+APIConfig
        }
      };
     return await axios(config)
}
async function GetWebGetUserCart(strCustomerCode,strOtherCountry,GuestCart){ 
    var config = {
        method: 'post',
        url: APIURL + '/api/WebGetUserCart?iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&strOtherCountry='+strOtherCountry+'&strCustomerCode='+strCustomerCode+'&accessCode='+accessCode,
        headers: {
          'Authorization': 'Basic '+APIConfig
        }, 
        data:GuestCart,
      };
     return await axios(config)
}
 async function WebDeleteUserCartandItem(strCustomerCode,strCartID,strBookCode){
    var config = {
        method: 'post',
        url: APIURL + '/api/WebDeleteUserCartandItem?iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+
        '&strBookCode='+strBookCode+'&strCustomerCode='+strCustomerCode+'&strCartID='+strCartID+'&accessCode='+accessCode,
        headers: {
          'Authorization': 'Basic '+APIConfig
        }
      };
     return await axios(config)
}
async function WebUpdateUserCart(strCustomerCode,strCartID,strBookCode,strBookQty){
  var config = {
      method: 'post',
      url: APIURL + '/api/WebUpdateUserCart?iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+
      '&strBookCode='+strBookCode+'&strCustomerCode='+strCustomerCode+'&strCartID='+strCartID+'&strBookQty='+strBookQty+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebGetWishList(strCustomerCode){

  var config = {
      method: 'post',
      url: APIURL + '/api/WebGetWishList?iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&strCustomerCode='+strCustomerCode+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}

async function WebGetUserLogin(strUserID,strPWD){

   var config = {
       method: 'post',
       url: APIURL+'/api/WebCustomerLogin?strUserID='+strUserID+'&strPWD='+strPWD+'&striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&accessCode='+accessCode,

       headers: {
         'Authorization': 'Basic '+APIConfig
       }
     };
    return await axios(config)
 }
 async function WebTodaySaying(){
var TodayQuoteID = 0;
  var config = {
      method: 'post',
      url: APIURL+'/api/WebTodaySaying?TodayQuoteID='+TodayQuoteID+'&iCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&accessCode='+accessCode,

      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
 

async function GetWebGetContactUs(str_UserName,str_Email, str_Phone, str_Message, UserID, Enquiry, sendEnquiryToEmail){   
    var config = {
        method: 'post',
        url: APIURL+'/api/WebContactUs?str_UserName='+str_UserName+'&str_Email='+str_Email+'&str_Phone='+ str_Phone +
        '&str_Message='+str_Message+'&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&UserID='+UserID+'&accessCode='+accessCode+
        '&Enquiry='+Enquiry+'&sendEnquiryToEmail='+sendEnquiryToEmail,
        headers: {
          'Authorization': 'Basic '+APIConfig
        }
      };
     return await axios(config)
}

async function CreateInsertUserCart(strCustomerCode,strBookCode,strBookQty,strBookClubID,GuestCart){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebInsertUserCart?strCustomerCode='+strCustomerCode+'&strBookCode='+strBookCode+'&strBookQty='+strBookQty+'&strBookClubID='+strBookClubID
      +'&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      },
      data:GuestCart,
    };
   return await axios(config)
}
async function GetUserCartandWishlistCount(strUserID){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetUserCartandWishlistCount?strUserID='+strUserID+'&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebInsertUserWishlist(WishlistID,strCustomerCode,strBookCode,strAction){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebInsertUserWishlist?strWishlistID='+WishlistID+'&strCustomerCode='+strCustomerCode+'&strBookCode='+strBookCode+'&strAction='+strAction
      +'&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebDeleteUserWishList(strCustomerCode,strBookCode){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebDeleteUserWishList?strCustomerCode='+strCustomerCode+'&strBookCode='+strBookCode
      +'&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function CreateInsertUser( CustName,  Mobile,  EmailID,  Userpassword,  UserType, ActionType, CustID,  CreatedBy, UpdatedBy,
  Remark, BillingAddress,  BillingCityID,  BillingPostalCode,  SchooName,  classyear, Stream, OtherInfo,  SchoolCode ){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebInsertUpdateWebsiteUser?CustName='+CustName+'&Mobile='+Mobile+'&EmailID='+EmailID+'&Userpassword='+Userpassword+
      '&UserType='+UserType+'&ActionType='+ActionType+'&CustID='+CustID+'&CreatedBy='+CreatedBy+'&UpdatedBy='+UpdatedBy+'&Remark='+Remark+
      '&BillingAddress='+BillingAddress+'&BillingCityID='+BillingCityID+'&BillingPostalCode='+BillingPostalCode+'&SchooName='+SchooName+
      '&classyear='+classyear+'&Stream='+Stream+'&OtherInfo='+OtherInfo+'&SchoolCode='+SchoolCode+'&CompanyID=31F7A37B-1200-4097-8A8D-0DD00DF56815'+
      '&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}

async function GetShoppingList(websearchtype,  websearchText,  Searchsubjectlist,  SearchsubjectlistID, PublisherID, AuthorID, LanguageID,
  TitleCategoryID,  Edition,  SearchSubsubjectlistID, TitleSubCategoryID,  PublishYear,  Binding,TotalRecord,  Sortby,  sortingAcenDesc,  SiteName,
  SchoolCode, MinPrice,  MaxPrice,  ExcludeOutofStock,  InterestAgeGroup,  BookClass,  BookMedium,  CourseID, ClassID, PageNumber, PageSize){

  var config = {
      method: 'post',
      url: APIURL+'/api/WebTitleSearchData?websearchtype='+websearchtype+'&websearchText='+websearchText+'&Searchsubjectlist='+Searchsubjectlist+
      '&SearchsubjectlistID='+SearchsubjectlistID+'&OtherCountry=INDIA&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&PublisherID='+PublisherID+
      '&AuthorID='+AuthorID+'&LanguageID='+LanguageID+'&TitleCategoryID='+TitleCategoryID+'&Edition='+Edition+'&SearchSubsubjectlistID='+SearchSubsubjectlistID+
      '&TitleSubCategoryID='+TitleSubCategoryID+'&PublishYear='+PublishYear+'&Binding='+Binding+'&TotalRecord='+TotalRecord+'&Sortby='+Sortby+
      '&sortingAcenDesc='+sortingAcenDesc+'&SiteName='+SiteName+'&SchoolCode='+SchoolCode+'&MinPrice='+MinPrice+'&MaxPrice='+MaxPrice+
      '&ExcludeOutofStock='+ExcludeOutofStock+'&InterestAgeGroup='+InterestAgeGroup+'&BookClass='+BookClass+'&BookMedium='+BookMedium+'&CourseID='+CourseID+
      '&ClassID='+ClassID+'&accessCode='+accessCode+'&PageNumber='+PageNumber+'&PageSize='+PageSize,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}

async function GetSearchListOnDemand(websearchtype,websearchText,CategoryID,SiteName,SchoolCode,PageNum,PageSize){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebTitleSearch?websearchtype='+websearchtype+'&websearchText='+websearchText+'&OtherCountry=INDIA&iCompanyID='+iCompanyID+
      '&iBranchID='+iBranchID+'&CategoryID='+CategoryID+'&SiteName='+SiteName+'&SchoolCode='+SchoolCode+'&accessCode='+accessCode+'&PageNum='+PageNum+
      '&PageSize='+PageSize,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}



async function getSubjectListBySubjectName(strActionType,strCol1,strCol2,strCol3,strCol4,strCol5){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetCommonMasterAPI?striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&strFinancialPeriod='+FinancialPeriod+'&accessCode='+accessCode
      +'&strActionType='+strActionType+'&strCol1='+strCol1+'&strCol2='+strCol2+'&strCol3='+strCol3+'&strCol4='+strCol4+'&strCol5='+strCol5,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
} 



async function GetSiteSubjectSubSubject(SubjectID){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetSiteSubjectSubSubjects?SubjectID='+SubjectID+'&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}

async function WebGetFooterDetails(TopicID,action){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetFooterDetails?TopicID='+TopicID+'&action='+action+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}

async function GetCategoryMultiLevel(strCategory){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetmultiLevelCategoryOfItem?striCompanyID='+iCompanyID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function GetValidateUserID(strUserEmail, strUserMobile, strSignUpvia){
  var strUserID;
  if(strSignUpvia === "MOBILE"){
    strUserID = strUserMobile;
  } else if(strSignUpvia === "EMAILID"){
    strUserID = strUserEmail;
  }else{
    strUserID = strUserEmail;
  }

  var config = {
      method: 'post',
      url: APIURL+'/api/WebValidateUserDetail?UserID='+strUserID+'&iCompanyID='+iCompanyID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}

async function GetUserDetail(strUserID){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetUserDetails?strCustomerID='+strUserID+'&striCompanyID='+iCompanyID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function GetCountryStateCity(Mode, CountryID, StateID){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetCountryStateCity?strMode='+Mode+'&strCountryID='+CountryID+'&strStateID='+StateID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebInsertUserShippingAddess(AddressID,CustomerCode,ShipAddress,ShipPostalCode,ShipCityID,ShipPhone,Mobile,EmailID,action,DeliveryPersonName){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebInsertUserShippingAddess?strAddressID='+AddressID+'&strCustomerCode='+CustomerCode+'&strShipAddress='+ShipAddress+'&strShipPostalCode='+ShipPostalCode+'&strShipCityID='+ShipCityID+'&strShipPhone='+ShipPhone+'&strMobile='+Mobile
      +'&strEmailID='+EmailID+'&action='+action+'&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&isDefault=false&accessCode='+accessCode+'&DeliveryPersonName='+DeliveryPersonName,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebGetUserShippingDetails(CustomerID,AddressID){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetUserShippingDetails?strCustomerID='+CustomerID+'&striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&strAddressID='+AddressID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebInsertOrderGetOrderIDforPayment(CheckoutID, TotalQty, TotalAmount, ShipCost, ShipMethod, Remark, Status, StatusRemark, transactionID,
               TID, OtherCountry, Courier, FinancialPeriod, CustomerCode, AddressID, CartID, PayStatus, PayStatusRemarks, action){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebInsertOrderGetOrderIDforPayment?strCheckoutID='+CheckoutID+'&strTotalQty='+TotalQty+'&strTotalAmount='+TotalAmount+'&strShipCost='+ShipCost+
      '&strShipMethod='+ShipMethod+'&strRemark='+Remark+'&strStatus='+Status+'&strStatusRemark='+StatusRemark+'&strtransactionID='+transactionID+'&strTID='+TID+
      '&strOtherCountry='+OtherCountry+'&strCourier='+Courier+'&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&strFinancialPeriod='+FinancialPeriod+
      '&strCustomerCode='+CustomerCode+'&strAddressID='+AddressID+'&strCartID='+CartID+'&strPayStatus='+PayStatus+'&strPayStatusRemarks='+PayStatusRemarks+
      '&straction='+action+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebInsertUserCheckout(AddressID,CustomerCode,CheckoutID,CartID,PayStatus,PayStatusRemarks,action){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebInsertUserCheckout?strCheckoutID='+CheckoutID+'&strCustomerCode='+CustomerCode+'&strAddressID='+AddressID+'&strCartID='+CartID+'&strPayStatus='+PayStatus+'&strPayStatusRemarks='+PayStatusRemarks+'&action='+action
      +'&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&isDefault=false&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}

async function WebGetPublisherList(strSiteName,strPublisherName){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetPublisher?strSiteName='+strSiteName+'&strPublisherName='+strPublisherName+'&striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebGetPublishYearList(strActionType,strCol1,strCol2,strCol3,strCol4,strCol5){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetCommonMasterAPI?striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&strFinancialPeriod='+FinancialPeriod+'&accessCode='+accessCode
      +'&strActionType='+strActionType+'&strCol1='+strCol1+'&strCol2='+strCol2+'&strCol3='+strCol3+'&strCol4='+strCol4+'&strCol5='+strCol5,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
} 
async function WebGetBundleList(strActionType,strCol1,strCol2,strCol3,strCol4,strCol5){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetCommonMasterAPI?striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&strFinancialPeriod='+FinancialPeriod+'&accessCode='+accessCode
      +'&strActionType='+strActionType+'&strCol1='+strCol1+'&strCol2='+strCol2+'&strCol3='+strCol3+'&strCol4='+strCol4+'&strCol5='+strCol5,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
} 

async function WebGetOrderHistory(CustomerCode,OrderID){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetOrderHistory?strCustomerCode='+CustomerCode+'&strIsAll=true&strOrderID='+OrderID+'&striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebGetOrderDertail(CustomerCode,OrderID){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetOrderDetail?strCustomerCode='+CustomerCode+'&strIsAll=false&strOrderID='+OrderID+'&striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebGetOrderHeader(CustomerCode,OrderID){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetOrderHeader?strCustomerCode='+CustomerCode+'&strIsAll=false&strOrderID='+OrderID+'&striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebGetOrderPayment(CustomerCode,OrderID){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetOrderPayment?strCustomerCode='+CustomerCode+'&strIsAll=false&strOrderID='+OrderID+'&striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebGetForgotPassword(strUserID,UserID){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetForgotPassword?strUserID='+strUserID+'&striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&UserID='+UserID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebInsertOrderPayment(OrderID,Status,Remark,StatusRemark,PayMethod,CardType,NameOnCard,CardNum,CardExpires, OtherDetail,transactionID,
  CustID,PaymentID,BankReferenceNo,amount){ 
  var config = {
      method: 'post',
      url: APIURL+'/api/WebInsertOrderPayment?strOrderID='+OrderID+'&strStatus='+Status+'&strRemark='+Remark+'&strStatusRemark='+StatusRemark+'&strPayMethod='+PayMethod+
      '&strCardType='+CardType+'&strNameOnCard='+NameOnCard+'&strCardNum='+CardNum+'&strCardExpires='+CardExpires+'&strOtherDetail='+OtherDetail+'&strtransactionID='+transactionID+
      '&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&accessCode='+accessCode+'&FinancialPeriod='+FinancialPeriod+'&CustID='+CustID+'&PaymentID='+PaymentID+
      '&BankReferenceNo='+BankReferenceNo+'&amount='+amount,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebCreateProductReview(ActionType,isApporved,strRating,strReviewDesc,CustID,ProductId,ReviewID,ReviewerHeading){
  var config = {
      method: 'post',
      url: APIURL+'/api/WebCreateProductReview?strActionType='+ActionType+'&strisApporved='+isApporved+'&strRating='+strRating+'&strReviewDesc='+strReviewDesc+'&strCustID='+CustID+'&strProductId='+ProductId+'&strReviewID='+ReviewID+'&strReviewerHeading='+ReviewerHeading+'&striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function WebGetOrderDetailforPayment(strCustomerID, strOrderID){
  var strSiteType = "WEBSITE";
  var config = {
      method: 'post',
      url: APIURL+'/api/WebGetOrderDetailforPayment?strCustomerID='+strCustomerID+'&strOrderID='+strOrderID+'&striCompanyId='+iCompanyID+'&striBranchID='+iBranchID+'&strFinancialPeriod='+FinancialPeriod+'&strSiteType='+strSiteType+'&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function webinsertinnewsletteremail(UserID){
  var strSiteType = "WEBSITE";
  var config = {
      method: 'post',
      url: APIURL+'/api/webinsertinnewsletteremail?strUserID='+UserID+'&striCompanyID='+iCompanyID+'&striBranchID='+iBranchID+'&strAction=1&accessCode='+accessCode,
      headers: {
        'Authorization': 'Basic '+APIConfig
      }
    };
   return await axios(config)
}
async function CreateInsertUserByndleCart(strCustomerCode,strBookCode,strBookQty,strBookClubID,GuestCart){
  var is ='Y'
  var config = {
      method: 'post',
      url: APIURL+'/api/WebInsertUserCartBookSet?strCustomerCode='+strCustomerCode+'&strBookCode='+strBookCode+'&strBookQty='+strBookQty+'&strBookClubID='+strBookClubID
      +'&iCompanyID='+iCompanyID+'&iBranchID='+iBranchID+'&accessCode='+accessCode+'&isSet='+is,
      headers: {
        'Authorization': 'Basic '+APIConfig
      },
      data:GuestCart,
    };
   return await axios(config)
}

export {BookSaleSliderAPI,GetWebGetSlideBanner1,WebGetSliderHomePage1,GetWebGetBookDetail,GetWebGetBookReview,GetWebGetSimilarBooks,
  GetWebGetUserCart,WebDeleteUserCartandItem,WebGetWishList,WebUpdateUserCart,WebGetCompanyDetail,GetWebGetContactUs,WebGetUserLogin,
  CreateInsertUserCart,WebGetSliderHomePage,GetWebGetSlideBanner2,WebTodaySaying,CreateInsertUser,GetUserCartandWishlistCount,
  GetShoppingList,WebCreateProductReview,GetSearchListOnDemand,WebGetOrderHistory,WebGetOrderHeader,GetSiteSubjectSubSubject,
  WebGetFooterDetails,WebGetForgotPassword,WebInsertUserWishlist,GetValidateUserID,GetUserDetail,WebGetSEO,
  GetCountryStateCity,WebInsertUserShippingAddess,WebGetUserShippingDetails,WebDeleteUserWishList,WebInsertOrderPayment,
  WebInsertOrderGetOrderIDforPayment, WebInsertUserCheckout,WebGetOrderDertail,WebGetPublisherList,WebGetPublishYearList,CreateInsertUserByndleCart,WebgetSiteMap,
  WebGetOrderPayment,WebEncryptData,WebGetOrderDetailforPayment,webinsertinnewsletteremail,GetCategoryMultiLevel,WebGetBundleList,getSubjectListBySubjectName,
}; 