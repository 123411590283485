import React,{useEffect,useState} from 'react';
import Index from './pages/Index';	
import {WebGetCompanyDetail ,GetWebGetUserCart} from './components/APISCollection';
import PuffLoader from "react-spinners/PuffLoader";
import Cookies from 'js-cookie'
//Css   // "node-sass": "^7.0.1",  removed from package.json
import './assets/css/style.css';
import "./assets/vendor/swiper/swiper-bundle.min.css";
//import UnderConstruction from './pages/UnderConstruction';
//import Cookies from 'universal-cookie'; 
	

function App() {	
const [loading, setLoading] = useState("");
var userID = Cookies.get('UserID');
const User = (userID === "guest" ? "guest" : userID);
	const UName = Cookies.get('UserID');
//const controller = new AbortController(); 
//const cookies = new Cookies();

const UserID =  Cookies.get('UserID');
useEffect(()=>{
	GetWebGetUserCart(User, 'INDIA').then(result => {
		Cookies.set('shpcst', (result.data[0].ShipCost));
	});
	var guestCart = Cookies.get("_guestUserCart");
				if(guestCart==="" || guestCart===null|| guestCart===undefined){
					let temp = [{ SlNo: 1, Item_ID: "", Item_QTY: 0 }];
					temp = JSON.stringify(temp);
					Cookies.set("_guestUserCart", temp); 
				}
	
	if(UserID === ""|| UserID === null || UserID === undefined ){
		Cookies.set('UserID', "guest");
	}  
	if(UserID === "guest"){
		var tmpGuestCart = Cookies.get("_guestUserCart");
		if(tmpGuestCart === "" || tmpGuestCart === null || tmpGuestCart === undefined){
			const current = new Date();
			const nextYear = new Date();
			nextYear.setFullYear(current.getFullYear() + 1);

			let temp = [{ SlNo: 1, Item_ID: "", Item_QTY: 0 }];
			temp = JSON.stringify(temp);
			Cookies.set("_guestUserCart", temp, { path: "/", expires:nextYear }); 
		}
	}
	setLoading(true);
     setTimeout(()=>{
		setLoading(false);
	 },50);
	 
},[]);
 

    useEffect(() => {	  
		var CompID = sessionStorage.getItem("iCompanyID");
		if(CompID === ""||CompID===null||CompID===undefined){ 
			WebGetCompanyDetail().then(result=>{
				//console.log(result.data); 
				sessionStorage.setItem("CompanyName", result.data[0].CompanyName)
				sessionStorage.setItem("iCompanyID", result.data[0].iCompanyId)
				sessionStorage.setItem("iBranchID", result.data[0].iBranchID) 
				sessionStorage.setItem("FinancialPeriod", result.data[0].FinancialPeriod)			
				sessionStorage.setItem("LocalCurrency", result.data[0].LocalCurrency)
				sessionStorage.setItem("SignUpVia", result.data[0].SignUpVia)
				sessionStorage.setItem("isSendEmail", result.data[0].isSendEmail)
				sessionStorage.setItem("isSendSMS", result.data[0].isSendSMS) 
				sessionStorage.setItem("SendEnquiryToEmail", result.data[0].SendBussSummaryEmail);
				sessionStorage.setItem("EnquirySubject", result.data[0].EnquirySubject);
				sessionStorage.setItem("CloudID", result.data[0].CloudUserID);				
			}) 
		}
		
		
		if(UserID === ""|| UserID === null || UserID === undefined ){
			Cookies.set('UserID', "guest");
		}  

		if(UserID === "guest"){
			var tmpGuestCart = Cookies.get("_guestUserCart");
			if(tmpGuestCart === "" || tmpGuestCart === null || tmpGuestCart === undefined){
				const current = new Date();
				const nextYear = new Date();
				nextYear.setFullYear(current.getFullYear() + 1);
	
				var temp = [{ SlNo: 1, Item_ID: "", Item_QTY: 0 }];
				temp = JSON.stringify(temp);
				Cookies.set("_guestUserCart", temp, { path: "/", expires:nextYear }); 
			}
		}

		// caches.keys().then((names) => {
		// 	names.forEach((name) => {
		// 	  caches.delete(name);
		// 	});
		//   }); 
	
      }, []);



	return (
		<>
		<div className="App">	
		{
			loading ?
			<div className='loader'>
				<PuffLoader color="black" loading = {loading} />
			</div>
			:
			<Index />  
		}
		</div>	
		</>
	);
}

export default App
