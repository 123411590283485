import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop2 from "react-scroll-to-top";
import React, { Suspense, useState, useEffect } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Cookies from 'js-cookie'
import {Helmet} from "react-helmet";
import { WebGetSEO } from '../components/APISCollection';
// import Topics from '../pages/Topics';


const Header = React.lazy(() => import('./../layouts/Header'));
const Footer = React.lazy(() => import('./../layouts/Footer'));
const ScrollToTop = React.lazy(() => import('./../layouts/ScrollToTop'));
const Main = React.lazy(() => import('../layouts/Main'));
//pages
const Home = React.lazy(() => import('./Home'));
const AboutUs = React.lazy(() => import('./AboutUs'));
const MyProfile = React.lazy(() => import('./MyProfile'));
const Services = React.lazy(() => import('./Services'));
const Faq = React.lazy(() => import('./Faq'));
const HelpDesk = React.lazy(() => import('./HelpDesk'));
const Pricing = React.lazy(() => import('./Pricing'));
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
// const BooksGridView = React.lazy(() => import('./BooksGridView'));
// const ShopList = React.lazy(() => import('./ShopList'));
const BooksGridViewSidebar = React.lazy(() => import('./BooksGridViewSidebar'));
// const BooksListViewSidebar = React.lazy(() => import('./BooksListViewSidebar'));
const ShopCart = React.lazy(() => import('./ShopCart'));
const Wishlist = React.lazy(() => import('./Wishlist'));
const Login = React.lazy(() => import('./Login'));
const Registration = React.lazy(() => import('./Registration'));
const ShopCheckout = React.lazy(() => import('./ShopCheckout'));
const ShopDetail = React.lazy(() => import('./ShopDetail'));
// const BlogGrid = React.lazy(() => import('./BlogGrid'));
// const BlogLargeSidebar = React.lazy(() => import('./BlogLargeSidebar'));
// const BlogListSidebar = React.lazy(() => import('./BlogListSidebar'));
// const BlogDetail = React.lazy(() => import('./BlogDetail'));
const ContactUs = React.lazy(() => import('./ContactUs'));
const ErrorPage = React.lazy(() => import('./ErrorPage'));
const UnderConstruction = React.lazy(() => import('./UnderConstruction'));
const ComingSoon = React.lazy(() => import('./ComingSoon'));
const Information = React.lazy(() => import('./Topics'));
const MyOrder = React.lazy(() => import('./MyOrder'));
const Summary = React.lazy(() => import('./Summary'));
const Review = React.lazy(() => import('./Revieworder'));
const Payresponse = React.lazy(() => import('./paymentresponse'));
const Moreitempage = React.lazy(() => import('./MoreItemPage'));
 const Carrer = React.lazy(() => import('./Cerrer'));
 const ManageAddress = React.lazy(() => import('./ManageShippingAddress'));
 const schoolBundel = React.lazy(() => import('./SchoolBundle'));
 const BundleDetails = React.lazy(() => import('./BundleDetail'));
 const SchoolDetails = React.lazy(() => import('./Pwd'));
 const sitemap = React.lazy(() => import('./sitemap'));
 const Errorpage = React.lazy(() => import('./ErrorPage'));
// const{ CoustServive,IntroductionTerms,PRIVACYPOLICY,TermOfAgreement,SECUREPAYMENT,SHIPPINGDELIVERY,Support}
// = React.lazy(()=> import('../pages/Topics'));
// const IntroductionTerms = React.lazy(()=> import('../pages/Topics'));
// const PRIVACYPOLICY = React.lazy(()=> import('../pages/Topics'));
// const TermOfAgreement= React.lazy(()=> import('../pages/Topics'));
// const SECUREPAYMENT = React.lazy(()=> import('../pages/Topics'));
// const SHIPPINGDELIVERY = React.lazy(()=> import('../pages/Topics'));
// const Support = React.lazy(()=> import('../pages/Topics'));
// const CoustServive = React.lazy(()=> import('../pages/Topics'));

//const logo = React.lazy(()=> import('./../assets/images/logo.png'));

 

function Index(props) {
	var baseName = '';
	const User =  Cookies.get('UserID');
	const [loading, setLoading] = useState("");
	var LoginFunction =Cookies.get('sclcd');
  
	useEffect(() => {
		
		
		setLoading(true);
		let clean = setTimeout(() => {
		  setLoading(false);
		}, 50);
	
		return () => {
		  clearTimeout(clean);
		};
	  }, []);
      WebGetSEO("WRITEXMLFOREDUCATION").then(reult=>{
		console.log(reult)
	  })
	  
	return (

		<BrowserRouter basename={baseName}>




			<Suspense fallback={<div>
				<div className='loader'>
					<PuffLoader color="black"
						loading={loading}
					/>
				</div>
			</div>}>
			<Helmet>
        
              <title>Education World - Complete Library Solution</title>
             <meta name="description" content="Education World Online book Store " />
                <link rel="canonical" href="https://educationworldweb.com/" />
            </Helmet>
				<div className="page-wraper">

					<Switch>
					   {/* <Route path='/sitemap.xml' exact component={sitemap} /> */}
						<Route path='/error-404' component={ErrorPage} />
						<Route path='/under-construction' component={UnderConstruction} />
						<Route path='/coming-soon' component={ComingSoon} />

						{/* <Route path="/index-2" exact>
						<Header />
							<Main>
								<Switch>
									<Route path='/index-2' exact component={Home2} />
								</Switch>
							</Main>
						<Footer  footerChange="footer-dark" logoImage={logoWhite} />		
					</Route> */}

						<Route>
						
						{ LoginFunction ===""|| LoginFunction ===null || LoginFunction ===undefined ?<><Header /></>:<>  <Header/> </> }

							<Main>
								<Switch>
									<Route path='/' exact component={Home} />
									<Route path='/about-us' exact component={AboutUs} />
									<Route path='/my-profile' exact component={User?MyProfile:Login} />
									<Route path='/services' exact component={Services} />
									<Route path='/faq' exact component={Faq} />
									<Route path='/help-desk' exact component={HelpDesk} />
									<Route path='/pricing' exact component={Pricing} />
									<Route path='/privacy-policy' exact component={PrivacyPolicy} />
									<Route path='/book-shop-search' exact component={BooksGridViewSidebar} />
									<Route path='/shop-cart' exact component={ShopCart} />
									<Route path='/wishlist' exact component={User?Wishlist:Login} />
									<Route path='/myorder' exact component={User?MyOrder:Login} />
									<Route path='/summary' exact component={User?Summary:Login} />
									<Route path='/shop-checkout' exact component={User?ShopCheckout:Login} />
									<Route path='/shop-login' exact component={Login} />
									<Route  path='/registration'><Registration/></Route>

									<Route path='/product-detail' exact component={ShopDetail} />
									<Route path='/contact-us' exact component={ContactUs} />
									<Route path='/company-info' exact component={Information} />
									<Route path='/review' exact component={Review} />
									<Route path='/payresponse' exact component={Payresponse} />
									<Route path='/viewmoreitems' exact component={Moreitempage} />
									<Route path='/career' exact component={Carrer} />
									<Route path='/manageaddress' exact component={ManageAddress} />
									<Route path='/schoolkit' exact component={schoolBundel} />
									<Route path='/setdetail' exact component={BundleDetails} />
									<Route path='/xyuhsf' exact component={SchoolDetails} />								

									
									<Route path='*' exact component={Errorpage} />
									{/* <Route path='/books-grid-view' exact component={BooksGridView} /> */}
									{/* <Route path='/ShopList' exact component={ShopList} /> */}
									{/* <Route path='/books-list-view-sidebar' exact component={BooksListViewSidebar} /> */}
									{/* <Route path='/shop-cart' exact component={User?ShopCart:Login} /> */}
									{/* //<Route path='/shop-registration'  exact component={Registration}  /> */}
									{/* <Route path='/blog-list-sidebar' exact component={BlogListSidebar} /> */}
									{/* <Route path='/blog-grid' exact component={BlogGrid} />
									<Route path='/blog-large-sidebar' exact component={BlogLargeSidebar} />
									<Route path='/blog-list-sidebar' exact component={BlogListSidebar} />
									<Route path='/blog-detail' exact component={BlogDetail} /> */}
									{/* <Route path ='/CoustServive' exact component={CoustServive}/>
									<Route path ='/TermOfAgreement' exact component={TermOfAgreement}/>
									<Route path ='/PRIVACYPOLICY' exact component={PRIVACYPOLICY}/>
									<Route path ='/SECUREPAYMENT' exact component={SECUREPAYMENT}/>
									<Route path ='/SHIPPINGDELIVERY' exact component={SHIPPINGDELIVERY}/>
									<Route path ='/IntroductionTerms' exact component={IntroductionTerms}/>
									<Route path ='/Support' exact component={Support}/>
									 */}
								</Switch>
							</Main>
							{/* <Topics/> */}
							<Footer />
						</Route>
					</Switch>
					<ScrollToTop />
					<ScrollToTop2 className="styles_scroll-to-top__2A70v  fas fa-arrow-up scroltop" smooth /> 
				</div>
			</Suspense>

		</BrowserRouter>
	)
}
export default Index;

